@import url('../../../styles/customMediaQueries.css');

.provider {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: row;
  gap: 15px;
  font-family: var(--fontFamilyHelmet);
  position: relative;
  width: 100%;
  padding-left: 40px;

  @media (--viewportMaxMedium) {
    padding-left: 13px;
  }
}

.sliderContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 10px;
  width: 100%;
  @media (--viewportSmall) and (--viewportMaxExtraMedium) {
    height: 280px;
  }
  @media (--viewportMaxSmall) {
    height: 230px;
    padding: 0;
  }
}

.slider {
  height: 480px;
  width: 100%;
  @media (--viewportMaxExtraMedium) {
    width: 100%;
    height: auto;
  }
  @media (--viewportMedium) and (--viewportMaxLarge) {
    height: 420px;
  }
  @media (--viewportExtraMedium) and (--viewportMaxMedium) {
    height: 310px;
  }
}
.slider div {
  @media (--viewportMaxExtraMedium) {
    width: 100% !important;
    display: block;
    height: 100%;
  }
}
.slider div div {

  @media (--viewportMaxExtraSmall) {
    width: 550% !important;
  }
  @media (--viewportExtraSmall) and (--viewportMaxSmall) {
    width: 450% !important;
  }

  @media (--viewportSmall) and (--viewportMaxExtraMedium) {
    width: 300% !important;
    display: flex;
    height: auto;
  }
}

.slider .slideborder {
  padding: 2px;
  max-width: 459px;
  width: 100% !important;
  @media (--viewportSmall) and (--viewportMaxExtraMedium) {
    width: 100% !important;
    max-width: 175px;
    height: auto;
    padding-bottom: 10.333% !important;
    margin: 0 4px;
  }
  @media (--viewportExtraSmall) and (--viewportMaxSmall) {
    width: 100% !important;
    max-width: 142px;
    height: auto;
    padding-bottom: 10.333% !important;
    margin: 0 4px;
  }
  @media (--viewportMaxExtraSmall) {
    width: 100% !important;
    max-width: 142px;
    height: auto;
    padding-bottom: 10.333% !important;
    margin: 0 4px;
  }
  @media (--viewportExtraMedium) and (--viewportMaxMedium) {
    width: 100% !important;
    max-width: 200px;
  }
  @media (--viewportMedium) and (max-width:850px) {
    width: 100% !important;
    max-width: 236px;
  }
  @media (min-width:850px) and (--viewportMaxLarge) {
    max-width: 263px;
    width: 100% !important;
  }
  @media (--viewportLarge) and (--viewportExtraMaxlarge) {
    max-width: 321px;
    width: 100% !important;
  }
  @media (--viewportExtralarge) and (max-width: 1438px) {
    max-width: 406px;
    width: 100% !important;
  }
}
.slider .slideborder div {
  @media (--viewportMaxExtraMedium) {
    width:  100% !important;
  }
}
.slider .slideborde .slide {
  @media (--viewportMaxExtraMedium) {
    height: auto;
    width: 41vmax !important;
  }
}

.slider .slideborder .slide {
  position: relative;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid black;
  padding: 22px;
  border-radius: 20px;
  overflow: hidden;
  @media (--viewportMedium) and (--viewportMaxLarge) {
    height: auto;
    padding: 18px;
  }
  @media (--viewportMaxMedium) {
    padding: 13px;
  }
}
.slider .slideborder .slide a {
  width: 100%;
  @media (--viewportExtraSmall) {
    width: 180px;
  }
}
.slider .slideborder .slide img {
  height: 346px;
  object-fit: cover;
  border: 1px solid black;
  border-radius: 20px;
  @media (--viewportMedium) and (--viewportMaxLarge) {
    height: 230px;
  }
  @media (--viewportLarge) and (--viewportExtraMaxlarge) {
    height: 300px;
  }
  @media (--viewportExtraMedium) and (--viewportMaxMedium) {
    height: 170px;
  }
  @media (--viewportSmall) and (--viewportMaxExtraMedium) {
    height: 160px;
  }
  @media (--viewportMaxSmall) {
    height: 130px;
  }
}
.slide:hover .profileLink {
  transform: scale(1);
}

.profileLink {
  position: absolute;
  width: 20vmax;
  top: 40%;
  left: 10%;
  color: black;
  font-size: 2.2vmax;
  text-decoration: underline;
  transform: scale(0);
  transition: transform 0.2s;
  z-index: 5;
  font-weight: 550;
  display: none;
  @media (--viewportMaxExtraMedium) {
    left: 20%;
  }
}

.slide:hover .img {
  opacity: 0.5;
  filter: blur(2px);
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 4;
  @media (--viewportMaxExtraMedium) {
    height: auto;
  }
}

.slider .slideborder .label {
  width: 100% !important;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 11px;
  @media (--viewportMaxExtraMedium) {
    height: auto;
  }
}
.slider .slideborder .label .labelMain {
  display: block !important;
}

.label h3 {
  color: black;
  font-size: var(--fontSizeDescription);
  margin: 0px;
  padding: 0;
  line-height: normal;
  font-weight: 400;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media (--viewportMaxExtraMedium) {
    width: 105px !important;
  }
  @media (--viewportSmall) and (--viewportMaxExtraMedium) {
    width: 110px !important;
  }
  @media (--viewportExtraMedium) and (--viewportMaxLarge) {
    width: 168px !important;
  }
  @media (--viewportMaxExtraSmall) {
    width: 153px !important;
  }
}

.label p {
  color: black;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  margin: 0px;
  padding: 0;
  line-height: normal;
}
.slider .slideborder .arrowImg {
  border: 1px solid black;
  width: 43px !important;
  height: 43px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (--viewportExtraMedium) and (--viewportMaxLarge) {
    width: 32px !important;
    height: 32px;
  }
  @media (--viewportMaxExtraMedium) {
    width: 40px !important;
    height: 32px;
  }
}
.slider .slideborder .arrowImg img {
  width: 29px !important;
  height: 12px !important;
  border: none !important;
  rotate: 180deg;
  @media (--viewportMaxLarge) {
    width: 23px !important;
    height: 10px !important;
  }
}
.btn {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (--viewportMaxExtraMedium) {
  .slide {
    width: 41vmax;
    height: auto;
  }
}

@media screen and (max-width: 960px) {
  .slide {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    box-sizing: border-box;
  }

  .img {
    object-fit: cover;
    object-position: center;
    z-index: 4;
  }

  .slider .slideborder .label {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100% !important;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-content: center;
    line-height: normal;
    padding: 0;
  }

  .label h3 {
    color: black;
    font-size: var(--fontSizeBase);
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .label p {
    color: black;
    font-family: var(--fontFamilyHelmet);
    font-style: italic;
    font-size: var(--fontSizeSubText);
    line-height: normal;
  }
}
.arrowContainer {
  border: 1px solid black;
  border-radius: 50px;
  width: 91px;
  height: 38px;
  position: absolute;
  top: -91px;
  right: 44px;
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  @media (--viewportMaxMedium) {
    top: -54px;
    width: 74px;
    right: 30px;
    padding: 0 4px;
  }
}
.nextBackBtn {
  rotate: 180deg;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderMainContainer {
  position: relative;
  padding-left: 40px;
  @media (--viewportMaxMedium) {
    padding-left: 20px;
  }
}
.slideBorder {
  border: 1px solid black;
  padding: 22px;
  border-radius: 20px;
  @media (--viewportMaxSmall) {
    padding: 22px 18px;
   
  }
  @media (--viewportSmall) and (--viewportMaxMedium) {
    padding: 18px;
  }
  @media (--viewportMaxMedium) {
    display: flex !important;
    justify-content: center;
  }
}
.slide a {
  width: 100%;
}
.slideBorder .slide img {
  border: 1px solid black;
  border-radius: 20px;
  object-fit: cover;
  @media (--viewportMaxExtraSmall) {
    width: 100%;
    height: 225px;
    margin: 0 auto;
  }
  @media (--viewportExtraSmall) {
    width: 100%;
    height: auto;
  }
}
.arrowImg img {
  border: none !important;
}
.label {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}
.labelMain h3 {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 25px;
  letter-spacing: var(--letterSpacingDescription);
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  text-align: left;
  @media (--viewportSmall) and (--viewportMaxExtraMedium) {
    width: 210px !important;
  }
  @media (--viewportExtraMedium) and (--viewportMaxLarge) {
    width: 165px !important;
  }
  @media (--viewportMaxExtraSmall) {
    width: 200px !important;
  } 
  @media (--viewportLarge) and (--viewportExtraMaxlarge) {
    width: 240px !important;
  }
}
.labelMain p {
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeDescription);
  font-weight: 400;
  line-height: 25px;
  letter-spacing: var(--letterSpacingDescription);
  text-transform: capitalize;
  margin: 0;
  padding: 0;
  @media (--viewportSmall) and (--viewportMaxMedium) {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100px; 
  }
  @media (--viewportMaxSmall) {
    width: 80px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  } 

}

.arrowImg {
  border: 1px solid black;
  width: 43px !important;
  height: 43px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (--viewportExtraMedium) and (--viewportMaxLarge) {
    width: 32px !important;
    height: 32px;
  }
  @media (--viewportMaxExtraMedium) {
    width: 32px !important;
    height: 32px;
  }
}
.arrowImg img {
  width: 29px !important;
  height: 12px !important;
  border: none !important;
  rotate: 180deg;
  @media (--viewportMaxLarge) {
    width: 23px !important;
    height: 10px !important;
  }
}
.arrowMain {
  display: flex;
  position: absolute;
  top: -92px;
  right: 84px;
  @media (--viewportMaxMedium) {
    top: -55px;
    right: 60px;
  }
}
.nextArrowContainer {
  border: 1px solid black;
  border-left: none;
  width: 45px;
  padding-right: 8px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  position: absolute;
  top: 0px;
  left: -1px;
  padding-left: 0;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
}
.prevArrowContainer {
  border: 1px solid black;
  border-right: none;
  width: 45px;
  padding-left: 8px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
}