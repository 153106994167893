@import url('../../../styles/customMediaQueries.css');

.provider {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  text-align: center;
  position: relative;
  font-family: var(--fontFamilyHelmet);
  padding: 0 60px;
  padding-top: 62px;
  padding-bottom: 65px;
  max-width: 1320px;
  margin: 0 auto;
  @media (--viewportMaxMedium) {
    padding: 0;
    height: 500px;
  }
  @media (min-width:1540px) {
   max-width:100%;
  }
}

.slider {
  width: 100%;
  height: auto;
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
  @media (--viewportMaxMedium) {
    border: none;
    border-radius: 0px;
  }
}

.slide {
  position: relative;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @media screen and (--viewportSmall) and (--viewportMaxLarge) {
    padding: 0 !important;
  }
  @media (min-width:769px) {
    height: 33vw;
    position: relative;
  }
}

.img {
  height: auto;
  z-index: 1;
  opacity: 48%;
  background-color: white;
  width: 194%;
  top: -481px;
  left: -255px;
  height: auto;
  position: absolute;
  @media (min-width:1540px) {
    top: -612px;
    left: -460px;
  }
  @media (--viewportXLarge) {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
  }
  @media (--viewportMaxMedium) {
    top: 0;
    height: auto;
    left: 0;
  }
  @media (--viewportMaxExtraSmall) {
   height: 100%; 
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-self: center;
  top: 0%;
  left: 7%;
  width: 68.5vw;
  z-index: 2;
  height: 100%;
  justify-content: center;
  gap: 10px;
  @media screen and (--viewportSmall) and (--viewportMaxLarge) {
    height: 100%;
    top: 0%;
    justify-content: center;
  }
}

.text {
  font-size: 55px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  font-family: var(--fontFamilyBethanyElingston);
  z-index: 2;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  @media (--viewportMaxLarge) {
    font-size: 40px;
    line-height: normal;
  }
  @media (--viewportMaxMedium) {
    -webkit-line-clamp: unset;
    text-align: center;
  }
  @media screen and (min-width: 769px) and (--viewportMaxLarge) {
    margin-bottom: 10px;
  }
  @media (--viewportExtralarge) {
    font-size: 75px;
    line-height: 90px;
  }
}

.container button {
  background-color: var(--colorLightYellow);
  color: black;
  border: 1px solid black;
  border-radius: var(--borderRadiusButton);
  max-width: fit-content;
  padding: 12px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  line-height: 24px;
  padding-bottom: 10px;
  transition: all 0.5s ease;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}
.backarrow {
  width: 30px;
  height: 14px;
  rotate: 180deg;
}

.container button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  box-shadow: none;
}

.dotGroup {
  position: absolute;
  left: 50%;
  top: 80%;
}

@media (--viewportMaxMedium) {
  .dotGroup {
    position: absolute;
    left: 44%;
    top: 76%;
  }
}
.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #bbb;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  @media (--viewportMaxMedium) {
    width: 8px;
    height: 8px;
    padding: 0;
  }
}

.dotSelected {
  background-color: var(--colorWhite);
}

.dot:hover {
  background-color: var(--colorWhite);
}

.dot:active {
  background-color: var(--colorWhite);
}

@media (--viewportMaxMedium) {
  .container {
    width: 85%;
    align-items: center;
  }
  .container button {
    background-color: var(--colorLightYellow);
    color: black;
    border: 1px solid black;
    border-radius: 30px;
    max-width: fit-content;
    padding: 12px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    font-family: var(--fontFamilyHelmet);
    font-size: var(--fontSizeDescription);
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 10px
  }
}

@media screen and (min-width: 769px) and (max-width: 960px) {
  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-content: left;
    align-self: center;
    left: 7%;
    width: 45vw;
    z-index: 2;
  }
}
@media screen and (max-width: 960px) {
  .slider {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 600px) {
  .slider {
    width: 100%;
    height: auto;
  }

  .slide {
    position: relative;
    padding: 0;
    display: flex;
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
  }
  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-content: left;
    align-self: center;
    left: 7%;
    width: 85%;
    z-index: 2;
    align-items: center;
    height: 100%;
  }
}
