@import url('../../styles/customMediaQueries.css');
.root {
  padding: 0.5vh 0vw;
  font-family: var(--fontFamilyHelmet);
  box-sizing: border-box;
  max-width: 100vw;
  max-height: 100vh;
  background: #fff;
}

.layout {
  display: grid;
  width: 100vw;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  background: #fff;
}

.mapsection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 100vw;
  height: 55vmax;
  padding-left: 35px;
  padding-right: 40px;
}

.mapcaption {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 16vmax;
}

.maptext {
  display: flex;
  align-self: center;
  color: black;
  font-size: 3.3vmax;
  font-weight: 600;
  flex-wrap: wrap;
  font-family: var(--fontFamilyHelmet);
  line-height: 1.3;
  padding-bottom: 0;
}

.mapcaption button {
  width: fit-content;
  padding: 12px;
  background-color: var(--colorLightYellow);
  color: black;
  border: 1px solid black;
  border-radius: var(--borderRadiusButton);
  cursor: pointer;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeButton);
  box-shadow: none;
  margin: 0 auto;
  margin-bottom: 20px;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
}

.mapcaption button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
  box-shadow: none;
}

.mapcontainer {
  display: flex;
  align-self: center;
  overflow: hidden;
  /* border: 5px solid blue; */
}

.carouselsection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  overflow: hidden;
}

.carouselsection h2 {
  padding: 0 40px;
  color: black;
  font-family: var(--fontFamilyBethanyElingston);
  font-size: var(--fontSizeHeading);
  font-weight: 700;
  line-height: 55.8px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  @media (--viewportMaxMedium) {
    padding: 0 20px;
    padding-right: 115px;
  }
  @media (--viewportMaxSmall) {
    display: flex;
    flex-direction: column;
  }
}

.carouselsection h2 span {
  font-size: var(--fontSizeHeading);
  font-family: var(--fontFamilyBethanyElingston);
  font-weight: 400;
  line-height: 75px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: left;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}
.carouselsection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.carouselsection h2 {
  color: black;
  font-size: var(--fontSizeHeading);
  font-weight: 600;
  font-family: var(--fontFamilyBethanyElingston);
  line-height: 3;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: normal;
  }
}

@media screen and (min-width: 960px) {
  .mapsection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    width: 100vw;
    height: 40vmax;
    padding-left: 35px;
    padding-right: 55px;
    /* border: 5px solid maroon; */
  }

  .mapcaption {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 30vw;
    height: 40vmax;
  }

  .maptext {
    display: flex;
    color: black;
    font-size: var(--fontSizeHeading);
    font-weight: 600;
    flex-wrap: wrap;
    font-family: var(--fontFamilyHelmet);
    line-height: 1.3;
    width: 100%;
  }

  .mapcaption button {
    width: fit-content;
    padding: 13px 20px;
    background-color: var(--colorLightYellow);
    color: black;
    border: 1px solid black;
    border-radius: var(--borderRadiusButton);
    cursor: pointer;
    font-size: var(--fontSizeButton);
    box-shadow: none;
    @media (--viewportMaxMedium) {
      font-size: var(--fontSizeDescription);
    }
  }

  .mapcaption button:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
    box-shadow: none;
  }
}

@media screen and (max-width: 960px) {
  .carouselsection h2 {
    color: black;
    font-family: var(--fontFamilyBethanyElingston);
  }
}
@media (--viewportMaxMedium) {
  .maptext {
    font-size: 24px;
  }
  .mapcaption button {
    font-size: var(--fontSizeDescription);
  }
}
.shopNeighbourhoodContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 15%;
  min-height: 53px;
  margin: auto;
  background-color: var(--colorLightYellow);
  overflow: hidden;
  z-index: 1;
  margin-top: 100px;
  @media (--viewportMaxSmall) {
    margin-top: 60px;
  }
  @media (--viewportExtralarge) {
    margin-top: 117px;
  }

}
.scrollAnimateContainer {
  display: flex;
  position: absolute;
  top: 13px;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  white-space: nowrap;
  transform: scale(2);
  transition: all 1s ease;
  @media (--viewportMaxMedium) {
    top: 12px;
  }
}
.scrollAnimateContainer > div {
  display: flex;
  animation: scrollText 10s infinite linear;
  height: 100%;
  align-items: center;
}

.scrollAnimateContainer h1 {
  margin: 0;
  margin-right: 50px;
  color: black;
  font-family: var(--fontFamilyHelmet);
  font-size: var(--fontSizeBase);
  font-weight: 400;
  letter-spacing: var(--letterSpacingDescription);
  transition: all 2s ease;
  text-align: right;
  padding-top: 3px;
  line-height: 13px;

  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeSmall);
    padding-top: 2px;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.shopLocallyContainer {
  margin:86px 0px;
  font-family: var(--fontFamilyHelmet);
  @media (--viewportMaxMedium) {
    margin: 80px 0px;
  }
}
.shopLocallyContainer h1 {
  font-family: var(--fontFamilyHelmet);
  font-size: 50px;
  font-weight: 400;
  line-height: 55.47px;
  letter-spacing: var(--letterSpacingDescription);
  text-align: center;
  color: black;
  padding: 0 40px;
  margin: 0;
  @media (--viewportMaxMedium) {
    font-size: 30px;
    line-height: normal;
  }
}
.shopLocallyContainer p {
  font-family:var(--fontFamilyBethanyElingston);
  font-size: 40px;
  font-weight: 400;
  line-height: 55px;
  text-align: center;
  
  margin: 0;
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  max-width: 1167px;
  padding: 0 20px;
  @media (--viewportMaxMedium) {
    font-size: 24px;
    line-height: 35px;
    margin-top: 14px;
  }
}
