@import url('../../../styles/customMediaQueries.css');

.neighborhoods {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  height: 45vmax;
  padding: 35px;
  padding-right: 45px;
}

.neighborhoodcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  width: 30vw;
  height: 40vmax;
  position: relative;
}

.heartmapicon {
  width: 8vmax;
  height: 8vmax;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  margin: 20px;
}

.neighborhoodtext {
  display: flex;
  padding: 30px;
  text-align: left;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  font-size: var(--fontSizeHeading);
  font-weight: 600;
  flex-wrap: wrap;
  font-family: var(--fontFamilyHelmet);
  line-height: 1.3;
  @media (--viewportMaxMedium) {
    font-size: 24px;
  }
}

.neighborhoodgrid {
  display: flex;
  align-items: center;
  flex-direction: column;
  column-gap: 15px;
  /* border: 5px solid blue; */
}

.neighborhoodrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 5px solid purple; */
}

.neighborhood {
  width: 30vmax;
  min-width: 300px;
  max-width: 535px;
  height: 20vmax;
  min-height: 200px;
  max-height: 350px;
  margin: 5px;
  position: relative;
}

.neighborhoodimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(70%);
}

.neighborhoodlabel {
  color: white;
  font-size: var(--fontSizeHeading);
  font-weight: light;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translatex(-50%);
  z-index: 9;
  white-space: nowrap;
  @media (--viewportMaxMedium) {
    font-size: 24px;
  }
}

.neighborhood button {
  background-color: var(--colorLightYellow);
  color: black;
  border-radius: var(--borderRadiusButton);
  border: 1px solid black;
  padding: 8px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translatex(-50%);
  z-index: 9;
  font-family: 'Libre Baskerville';
  font-size: var(--fontSizeButton);
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeDescription);
  }
  @media screen and (min-width: 769px) and (--viewportExtraMaxlarge) {
    width: calc(100% - 20%);
  }
}

.neighborhood button:hover {
  background-color: white;
  color: black;
  border-color: black;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  background-color: #f9fafb;
  width: 100vw;
  /* border: 5px solid pink; */
}

@media screen and (max-width: 960px) {
  .neighborhoods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: auto;
    /* border: 4px solid red; */
  }

  .neighborhoodcaption {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    width: 80vw;
    height: 30vmax;
    /* border: 5px solid black; */
  }

  .heartmapicon {
    width: 8vmax;
    height: 8vmax;
    align-self: center;
    /* border: 2px solid pink; */
  }

  .neighborhoodtext {
    display: flex;
    align-self: center;
    text-align: center;
    color: black;
    font-weight: 600;
    flex-wrap: wrap;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
    width: 100%;
    /* border: 2px solid black; */
  }

  .neighborhoodgrid {
    display: flex;
    align-items: center;
    flex-direction: column;
    column-gap: 15px;
    /* border: 5px solid blue; */
  }

  .neighborhood {
    position: relative;
  }

  .neighborhoodimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(70%);
  }

  .neighborhoodlabel {
    color: white;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translatex(-50%);
    z-index: 9;
    white-space: nowrap;
  }

  .neighborhood button {
    background-color: var(--colorLightYellow);
    color: black;
    border: 1px solid black;
    border-radius: 30px;
    padding: 12px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    position: absolute;
    top: 72%;
    left: 50%;
    transform: translatex(-50%);
    z-index: 9;
  }

  .neighborhood button:hover {
    background-color: white;
    color: black;
    border-color: black;
  }
}

@media (--viewportMaxMedium) {
  .neighborhood {
    max-width: 280px;
    height: 200px;
  }
}

@media (--viewportMaxMedium) {
  .neighborhoodrow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* border: 5px solid purple; */
  }

  .neighborhoods {
    padding: 20px 20px;
  }
  .neighborhood button {
    padding: 12px;
    font-size: var(--fontSizeDescription);
    top: 57%;
    left: 50%;
    width: 100%;
    max-width: calc(100% - 50px);
  }
}

@media screen and (max-width: 500px) {
  .dotGroup {
    position: absolute;
    left: 47%;
    top: 86%;
  }
  .neighborhoodtext {
    font-size: var(--fontSizeButton);
    padding: 10px 0;
    padding-top: 30px;
  }
  .neighborhoodcaption {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: center;
    width: 100%;
    height: 26vmax;
    /* border: 5px solid black; */
  }
  .neighborhoodlabel {
    color: white;
    font-size: 24px;
    font-weight: light;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translatex(-50%);
    z-index: 9;
    white-space: nowrap;
  }
  .heartmapicon {
    left: 43%;
  }
}
.howWorkContainer {
  width: 100%;
}
.howWorkContainer h1 {
  font-size: var(--fontSizeHeading);
  font-weight: 400;
  line-height: 50px;
  text-align: center;
  font-family: var(--fontFamilyHelmet);
  border-top: 1px solid black;
  padding: 27px 0;
  margin: 0;

  @media (--viewportMaxMedium) {
    font-size: 24px;
    padding: 5px 0;
  }
}
.howWorkContainer table {
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-collapse: collapse;
  background-color: #f5f3f1;
  table-layout: auto;
  @media (--viewportMaxLarge) {
    padding:73px 0 ;
  }
}
.howWorkContainer table td {
  border-right: 1px solid black;
  padding: 0px 20px;
  width: 33.333%;
  @media (--viewportMedium) and (--viewportMaxLarge) {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none;
    justify-content: center;
    padding-top: 57px;
    padding-bottom: 0;
  }
  @media (--viewportMaxMedium) {
    padding-top: 35px;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: none;
    justify-content: center;
    padding-bottom: 0;
  }
}
.howWorkContainer table td:last-child {
  border-right: none;
  @media (--viewportMedium) and (--viewportMaxLarge){
    padding-bottom: 57px;
  }
  @media (--viewportMaxMedium) {
    padding-bottom: 35px;
  }
}
.buttonContainer {
  padding: 58px 0px;
  height: 302px;
  @media (--viewportMaxLarge) {
    padding: 0;
    height: auto;
  }
}
.howWorkContainer table td button {
  width: 100%;
  max-width: 348px;
  background-color: var(--colorLightYellow);
  padding: 10px 19px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71px;
  border-radius: 70px;
  transition: all 0.3s ease;
  margin: 0 auto;
  @media (--viewportExtralarge) {
    padding: 10px 42px;
  }
}

.numberButton {
  font-family: var(--fontFamilyHelmet);
  font-size: 60px;
  color: black;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  padding-right: 14px;
  margin: 0;
  @media (--viewportMaxMedium) {
    font-size: 35px;
    line-height: normal;
  }
}
.buttonDescription {
  font-family: var(--fontFamilyHelmet);
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: black;
  padding: 0;
  margin: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  @media (--viewportMaxMedium) {
    font-size: var(--fontSizeBase);
    line-height: normal;
  }
}
.worksDetail {
  font-family: var(--fontFamilyHelmet);
  font-size: 23px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 0 auto;
  padding: 0;
  margin-top: 26px;
  width: 100%;
  max-width: 323px;
  @media (--viewportMaxLarge) {
    font-size: var(--fontSizeSubText);
    line-height: normal;
    margin-top: 13px;
  }
}
